<template>
  <div class="document_box">
    <div class="content_box">
      <div class="content">

        <div class="content_left">

          <div class="top">
            <span @click="goBack" class=" hand">&lt;&lt; 返回上级</span>
          </div>
          <h3 class="video_title">{{documentDetails.title}}</h3>

          <iframe v-if="!/.ppt/g.test(documentDetails.fileUrl)" ref="iframe" :src="`./pdfjs/web/viewer.html?file=${this.documentDetails.fileUrl}&type=infoCollege`" frameborder="0" scrolling="no" noResize class="iframe"></iframe>
          <iframe v-else class="iframe" :src="`https://view.officeapps.live.com/op/embed.aspx?src=${documentDetails.fileUrl}&amp;wdAr=1.7777777777777777`" frameborder="0">这是嵌入 <a target="_blank" href="https://office.com">Microsoft Office</a> 演示文稿，由 <a target="_blank" href="https://office.com/webapps">Office</a> 提供支持。</iframe>
        </div>

        <div class="content_right">

          <div class="top">
            <span>当前分类</span>
          </div>

          <div class="type_box">
            <span>{{documentParameter.categoryName}}</span>
          </div>

          <div class="top">
            <span>其他</span>
          </div>

          <ul class="document_list">
            <li @click="onDocumentListClick(item)" v-for="item in documentList" :key="item.id">
              <div class="img_box">
                <img class="videoPic" :src="item.filePic" alt="">
              </div>
              <p>{{item.title}}</p>
              <i class="iconfont icon-dangqian icon" v-if="item.id === documentDetails.id"></i>
            </li>
            <li class="last_children ">
              <span v-if="isShow" @click="onReplaceDocumentList" class=" hand"><i class="el-icon-refresh"></i>换一批</span>
              <span v-else>没有更多了</span>
            </li>
          </ul>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getDocumentDetails, getDocumentList } from '@/api/infoCollege.js'
export default {
  data () {
    return {
      documentDetails: {}, // 文档详情
      documentParameter: {}, // 会话储存参数
      pageObj: {
        pageNum: 1,
        pageSize: 4
      },
      documentList: [],
      isShow: true
    }
  },
  methods: {
    // 列表点击事件
    onDocumentListClick (item) {
      if (item.id === this.documentDetails.id) return this.$message.warning('已展示当前资料')
      this.documentParameter.documentId = item.id
      this.getDocumentDetails()
      // myScrollBar
      this.$refs.iframe.contentWindow.myScrollBar()
    },
    // 换一换
    onReplaceDocumentList () {
      this.pageObj.pageNum++
      this.getDocumentList()
    },
    // 获取资料列表
    async getDocumentList () {
      const res = await getDocumentList({
        ...this.pageObj,
        classify: this.documentParameter.categoryId
      })
      this.isShow = res.data.length >= this.pageObj.pageSize
      // this.documentList = res.data.filter(item => (!/.ppt/g.test(item.fileUrl)))
      this.documentList = res.data
    },
    // 返回上层
    goBack () {
      this.$router.push('/infoCollege')
    },
    // 获取文献详情
    async getDocumentDetails () {
      const res = await getDocumentDetails({
        documentId: this.documentParameter.documentId
      })
      this.documentDetails = res.data
    }
  },
  created () {
    this.documentParameter = JSON.parse(sessionStorage.getItem('documentParameter') || '{}')
    this.getDocumentDetails()
    this.getDocumentList()
    window.myid = () => {
    }
    window.hide = () => {
    }
  }
}
</script>
<style lang="less" scoped>
.document_box {
  width: 100%;
  min-height: 100%;
  background-color: #f5f7fb;
  .content_box {
    width: 100%;
    min-height: 100%;
    background-color: #f5f7fb;
    .content {
      width: 1200px;
      min-height: 736px;
      margin: auto;
      display: flex;
      padding: 20px 0;
      .top {
        font-size: 18px;
      }
      .content_left {
        width: 870px;
        border-right: 1px solid #9faccc;
        padding-right: 20px;
        .video_title {
          font-size: 20px;
          color: #4b639f;
          margin: 10px 0 15px;
        }
        .iframe {
          width: 100%;
          height: calc(100% - 70px);
        }
      }

      .content_right {
        width: 330px;
        padding-left: 20px;
        .type_box {
          display: flex;
          margin: 10px 0 15px 0;
          span {
            padding: 5px 15px;
            border-radius: 3px;
            border: 1px solid #4b639f;
            color: #4b639f;
            background-color: #fff;
          }
        }

        .document_list {
          margin-top: 15px;
          li {
            position: relative;
            display: flex;
            width: 100%;
            height: 120px;
            padding: 20px;
            margin-bottom: 20px;
            border-radius: 5px;
            background-color: #fff;
            &:hover {
              box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
              .videoPic {
                transform: scale(1.15);
              }
            }
            .img_box {
              width: 90px;
              margin-right: 15px;
              overflow: hidden;
              flex-shrink: 0;
            }
            .videoPic {
              width: 90px;
              transform: scale(1.01);
              transition: transform 0.4s;
            }
            .icon {
              position: absolute;
              top: -10px;
              right: 0;
              font-size: 32px;
              color: #4b639f;
            }
            p {
              line-height: 20px;
              font-size: 14px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 4;
              -webkit-box-orient: vertical;
              word-break: break-all;
            }
          }
          .last_children {
            width: 100%;
            height: 40px;
            align-items: center;
            justify-content: center;
            background-color: transparent;
            &:hover {
              box-shadow: 0 0 0 0 transparent;
              span {
                color: #4b639f;
              }
            }
            span {
              font-size: 16px;
              i {
                margin-right: 5px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
